export const BLOG_TOPICS = Object.freeze({
    GMAT: 'gmat',
    GRE: 'gre',
    TOEFL: 'toefl',
    IELTS: 'ielts'
});
export const BLOG_TITLES = Object.freeze({
    [BLOG_TOPICS.GMAT]: {
        title: 'GMAT Blogs: Everything You Need to Learn About GMAT | Yocket Prep'
    },
    [BLOG_TOPICS.GRE]: {
        title: 'GRE Blogs: Everything You Need to Learn About GRE | Yocket Prep'
    },
    [BLOG_TOPICS.IELTS]: {
        title: 'IELTS Blogs: Everything You Need to Learn About IELTS | Yocket Prep'
    },
    [BLOG_TOPICS.TOEFL]: {
        title: 'TOEFL Blogs: Everything You Need to Learn About TOEFL | Yocket Prep'
    }
});
export const BLOG_HEADER_INFO = Object.freeze({
    [BLOG_TOPICS.GRE]: {
        title: 'Strengthen your GRE Prep!',
        subTitle: 'Stay ahead of the competition by reading these expert curated GRE Prep articles.'
    },
    [BLOG_TOPICS.GMAT]: {
        title: 'Master the GMAT: Tips, Strategies, and Insights for Success',
        subTitle:
            'Discover top GMAT strategies, study tips, and success stories to help you score higher. Your journey to a top business school starts here.'
    },
    [BLOG_TOPICS.TOEFL]: {
        title: 'Boost Your TOEFL Score: Essential Tips & Strategies',
        subTitle:
            'Explore in-depth guides, practice questions, and expert advice to maximize your TOEFL performance and secure your spot in top universities worldwide.'
    },
    [BLOG_TOPICS.IELTS]: {
        title: 'Boost Your IELTS Score: Essential Tips & Strategies',
        subTitle:
            'Explore in-depth guides, practice questions, and expert advice to maximize your IELTS performance and unlock global opportunities.'
    }
});

export const BLOG_META_INFO = Object.freeze({
    [BLOG_TOPICS.GMAT]: {
        title: 'Complete GMAT Exam Prep Planner Online | Yocket Prep',
        description: 'Complete GMAT Exam Prep Planner Online | Yocket Prep'
    },
    [BLOG_TOPICS.GRE]: {
        title: 'Complete GRE Exam Prep Planner Online | Yocket Prep',
        description: 'Complete GRE Exam Prep Planner Online | Yocket Prep'
    },
    [BLOG_TOPICS.TOEFL]: {
        title: 'Complete TOEFL Exam Prep Planner Online | Yocket Prep',
        description: 'Complete TOEFL Exam Prep Planner Online | Yocket Prep'
    },
    [BLOG_TOPICS.IELTS]: {
        title: 'Complete IELTS Exam Prep Planner Online | Yocket Prep',
        description: 'Complete IELTS Exam Prep Planner Online | Yocket Prep'
    }
});
