import { render, staticRenderFns } from "./NavbarTablet.vue?vue&type=template&id=b0f94590&scoped=true&"
import script from "./NavbarTablet.vue?vue&type=script&lang=js&"
export * from "./NavbarTablet.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0f94590",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Chevron: require('/app/assets/Icons/Chevron.vue').default,ProfilePic: require('/app/components/Profile/ProfilePic.vue').default})
