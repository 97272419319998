//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
// importing components
import Navbar from '~/components/Skeleton/Navbar.vue';
import analyticEvents, { ANALYTIC_SOURCE_MODULES } from '~/services/analyticEvents';
// import 'animate.css';
import { ROUTES } from '~/constants/pageRoutes';
import { isBotUserAgent, toast } from '~/services/utilityFunctions.js';
import { ACQUISITION_SOURCE_CTA, AUTH_FLOW, AUTH_STEP, LOGIN_METHOD } from '~/constants/authFlows';
import { trackLogin } from '~/services/analyticsTracker';
import { BLOG_TOPICS } from '~/constants/blogConstants';
const MobileSideBar = () => import('~/components/Navbar/Pwa/MobileSideBar.vue');
const TabletSidebar = () => import('~/components/Navbar/Tablet/TabletSidebar.vue');
const ExamDetailsFillingModal = () => import('~/components/Auth/ExamDetailsFillingModal.vue');
const ModalContainer = () => import('~/components/ReusableComponents/ModalContainer.vue');
const AuthModal = () => import('~/components/Auth/AuthModal.vue');
const ChatbotFab = () => import('~/components/ReusableComponents/ChatbotFab.vue');

// const AppWideFeatureInfoBanner = () =>
//     import('~/components/ReusableComponents/AppWideFeatureInfoBanner.vue');
const CDN = process.env.CDN_ENDPOINT + '/testprep/static/images';

export default {
    name: 'Default',
    components: {
        Navbar,
        MobileSideBar,
        TabletSidebar,
        ExamDetailsFillingModal,
        ModalContainer,
        AuthModal,
        ChatbotFab
        // AppWideFeatureInfoBanner
    },
    data() {
        return {
            isAcquisitionAuth: false,
            gAuthPopupSignup: false,
            isChatbotOpen: false
        };
    },
    computed: {
        ...mapGetters({
            fetchingUser: 'auth/fetchingUser',
            sideBarOpen: 'UIElementsState/getSideBarStatus',
            profileCompletionLevel: 'auth/profileCompletionLevel',
            showAcquisitionAuthModal: 'auth/showAcquisitionAuthModal',
            showInstructions: 'testSets/showInstructions',
            userCountry: 'auth/userCountry',
            authFlow: 'auth/authFlow',
            isAuthenticated: 'auth/isAuthenticated',
            onboardingStep: 'auth/onboardingStep',
            user: 'auth/getUser',
            testType: 'auth/testType',
            isDiagnosticTestAuth: 'auth/isDiagnosticTestAuth'
        }),
        webStepZeroSignupHeading() {
            if (this.isDiagnosticTestAuth) {
                return 'Start your Test Prep Journey Today!';
            }
            return 'Start your Test Prep Journey Today!';
        },
        webStepZeroSignupSubHeading() {
            if (this.isDiagnosticTestAuth) {
                return 'Sign up for a free trial with no additional commitments.';
            }
            return 'Just sign up once!';
        },
        illustrationImg() {
            if (this.isDiagnosticTestAuth) {
                return `${CDN}/authModalGreQDTest.webp`;
            }
            return `${CDN}/authModalTestCard.webp`;
        },
        isOnboardingNotCompleted() {
            return [
                AUTH_STEP.EXAM_DETAILS,
                AUTH_STEP.OTP_VERIFICATION,
                AUTH_STEP.USER_AND_EXAM_DETAILS
            ].includes(this.onboardingStep?.stepName);
        },
        allowedAcquisitionModalPage() {
            return (
                (this.$route.path.includes(ROUTES.GMAT_BLOGS_AND_GUIDES) ||
                    this.$route.path.includes(ROUTES.GRE_BLOGS_AND_GUIDES)) &&
                !this.$route.path.includes(ROUTES.GRE_QUICK_DIAGNOSTIC) &&
                !this.$route.path.includes(ROUTES.CLASSES_LP) &&
                !this.showInstructions
            );
        },
        shouldShowChatbotOnPage() {
            const isIndianUser = this.userCountry === 'IN';
            const isBlogsOrGuidesOrHomePage = [
                'gre-blogs',
                'gmat-blogs',
                'gre-slug',
                'gmat-slug',
                'index'
            ].includes(this.$route.name);
            return isIndianUser && !this.isAuthenticated && isBlogsOrGuidesOrHomePage;
        },
        bannerExcludedPage() {
            const isExcluded = [ROUTES.FREE_STARTER_KIT, ROUTES.FREEBIES].includes(
                this.$route.path
            );
            return isExcluded;
        },
        isNonDismissableModal() {
            if (process.client) {
                return Boolean(window.sessionStorage.getItem('acq_auth_shown'));
            }

            return false;
        },
        fabBottomPosition() {
            const isGreBlogsListingPage = this.$route.name === 'gre-blogs';
            const isGreBlogSlugOrHomePage = ['gre-slug', 'index'].includes(this.$route.name);

            if (
                !isGreBlogsListingPage &&
                isGreBlogSlugOrHomePage &&
                this.$device.isMobileOrTablet
            ) {
                return 'bottom-24';
            }
            return 'bottom-12';
        }
    },
    watch: {
        isDiagnosticTestAuth: {
            immediate: true,
            handler: function (value) {
                if (value) {
                    this.$store.dispatch('analytics/trackEvent', {
                        event: analyticEvents.redirection_modal_shown,
                        data: {
                            source: ANALYTIC_SOURCE_MODULES.GRE_DIAGNOSTIC_TEST_REPORT
                        }
                    });
                }
            }
        },
        showAcquisitionAuthModal: {
            immediate: true,
            handler: function (value) {
                if (value) {
                    this.isAcquisitionAuth = true;
                    this.$store.commit(
                        'auth/setSignupCtaSource',
                        this.isNonDismissableModal
                            ? ACQUISITION_SOURCE_CTA.non_dismissable_redirection_modal
                            : ACQUISITION_SOURCE_CTA.dismissable_redirection_modal
                    );
                    this.$store.dispatch('analytics/trackEvent', {
                        event: analyticEvents.redirection_modal,
                        data: {
                            modal_type: this.isNonDismissableModal
                                ? 'non_dismissable'
                                : 'dismissable'
                        }
                    });
                }
            }
        }
    },

    mounted() {
        if (!this.isAuthenticated && !this.showAcquisitionAuthModal) {
            setTimeout(() => {
                if (this.$route.path !== ROUTES.LOGIN) {
                    this.loadGoogleAuthScript();
                }
            }, 3000);
        }
        this.attachDocumentEvents();
        console.log('user agent', window.navigator.userAgent);

        // Importing it later on asynchronously so as to avoid it in initial bundle
        setTimeout(() => {
            import('animate.css');
        }, 1000);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.clickListener);
    },
    methods: {
        toggleChatbot() {
            this.isChatbotOpen = !this.isChatbotOpen;
        },
        loadGoogleAuthScript() {
            const thisComponent = this;
            const script = document.createElement('script');
            script.type = 'text/javascript';

            script.addEventListener('load', (event) => {
                // eslint-disable-next-line no-undef
                google.accounts.id.initialize({
                    client_id: process.env.GOOGLE_OAUTH_CLIENT_ID,
                    cancel_on_tap_outside: false,
                    callback: (response) => {
                        thisComponent.handleGoogleAuth(response);
                    }
                });

                // eslint-disable-next-line no-undef
                google.accounts.id.prompt(); // also display the One Tap dialog

                this.$store.dispatch('analytics/trackEvent', {
                    event: analyticEvents.google_auth_popup_shown
                });
            });
            script.src = 'https://accounts.google.com/gsi/client';
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
        },
        async handleGoogleAuth(response) {
            this.$store.commit('auth/setSignupCtaSource', ACQUISITION_SOURCE_CTA.prep_gauth_popup);
            // 1. Set the relevant auth flow (INDIAN_GOOGLE or INTERNATIONAL_GOOGLE)
            this.gAuthPopupSignup = true;
            this.$store.commit('auth/setAuthFlow', {
                isInternationalUser: this.userCountry !== 'IN',
                loginMethod: LOGIN_METHOD.SOCIAL
            });

            // 2. Save the jwt credential in store
            const jwt = response.credential;
            this.$store.commit('auth/setGoogleLoginCredential', jwt);

            try {
                const gaClientId = await new Promise((resolve) => {
                    try {
                        window.gtag('get', process.env.GA_TRACKING_ID, 'client_id', (clientId) =>
                            resolve(clientId)
                        );
                        // if above callback (4th param) not called for 200 ms, resolve the promise
                        setTimeout(function () {
                            resolve(undefined);
                        }, 200);
                    } catch (error) {
                        resolve(undefined);
                    }
                });

                // 3. Construct api payload body
                // Mandatory fields
                const body = {
                    signin_method: 'google',
                    credentials: jwt,
                    device_source: 'w',
                    ga_client_id: gaClientId,
                    chatbot_consent: false
                };

                const {
                    data: { data, state }
                } =
                    this.userCountry === 'IN'
                        ? await this.$api.auth.indianSocialSignIn(body)
                        : await this.$api.auth.internationalSocialSignin(body);

                if (!state) {
                    const toastPayload = {
                        message: 'Something went wrong. Please try again later.'
                    };
                    toast.failureToast(toastPayload);
                    return;
                }

                this.$store.dispatch('analytics/trackEvent', {
                    event: analyticEvents.google_auth_signup,
                    data: {
                        source_cta: 'gauth_popup',
                        is_new_user: data.is_new_user,
                        source: this.$route.path.split('/').pop()
                    }
                });

                // Set the returned user object in store
                this.$store.commit('auth/setUser', data.user);
                this.$store.commit('auth/setCandidateFirstName', data.user?.first_name || '');
                this.$store.commit('auth/setCandidateLastName', data.user?.last_name || '');

                // If no basic user details are filled (fresh signup) then take to signup page
                if (data.is_new_user) {
                    this.$store.commit('auth/setNextOnboardingStep');
                }
                // Else login and make the user fill necessary details in popup modal
                else {
                    await this.$store.dispatch('auth/getLoggedInUser');

                    // confirm GA event to fire

                    const trackingEvent = {
                        event: analyticEvents.login_success
                    };
                    this.$store.dispatch('analytics/trackEvent', trackingEvent);

                    let continueRoute = ROUTES.HOME;
                    if (this.testType === BLOG_TOPICS.GMAT) {
                        continueRoute = ROUTES.GMAT_BLOGS_AND_GUIDES;
                    } else if (!this.$device.isMobile) {
                        continueRoute = ROUTES.DASHBOARD;
                    }

                    // Reset plans object and then fetch according to logged in user
                    this.$store.commit('premium/setAllPlans', null);
                    this.$store.commit('auth/clearAcquisitionAuthTimer', { hideModal: true });

                    this.$router.push(continueRoute);

                    // DO NOT DELETE
                    trackLogin(this.user); // Track user UUID.
                }

                // GA event
                const trackingEvent = {
                    event: analyticEvents.google_signin_success
                };
                this.$store.dispatch('analytics/trackEvent', trackingEvent);
                // signup_success when new google signup
                if (data.is_new_user && this.authFlow === AUTH_FLOW.INTERNATIONAL_GOOGLE) {
                    const trackingEvent = {
                        event: analyticEvents.signup_success
                    };
                    this.$store.dispatch('analytics/trackEvent', trackingEvent);
                }
            } catch (error) {
                let errorMessage = 'Something went wrong. Please try again later.';
                if (error.response.data.errors.message)
                    errorMessage = error.response.data.errors.message;
                const toastPayload = {
                    message: errorMessage
                };
                toast.failureToast(toastPayload);
            }
        },
        isBotUserAgent,
        attachDocumentEvents() {
            document.addEventListener('click', this.clickListener);
        },
        clickListener(event) {
            if (
                this.sideBarOpen &&
                !event.target.closest('#side-bar-menu') &&
                !event.target.closest('#side-bar-hamburger')
            ) {
                this.$store.commit('UIElementsState/setSideBarStatus', false);
            }
        },
        acquisitionSignupHandler() {
            this.$store.commit('auth/clearAcquisitionAuthTimer');
            this.$store.commit('auth/toggleTimerBasedAcqAuthModal', false);
            window.location.reload();
        },
        closeAuthModal() {
            if (this.isDiagnosticTestAuth) {
                this.$store.commit('auth/setDiagnosticTestAuth', false);
            } else if (this.isAcquisitionAuth) {
                this.$store.commit('auth/toggleTimerBasedAcqAuthModal', false);

                // Fire analytic event
                const trackingEvent = {
                    event: analyticEvents.signup_acqmodal_dismiss
                };
                this.$store.dispatch('analytics/trackEvent', trackingEvent);
                this.$store.commit('auth/resetOnboardingStep');
                this.$store.commit('auth/resetOnboardingDetails');
            } else if (this.gAuthPopupSignup) {
                this.gAuthPopupSignup = false;
            }
        }
    }
};
