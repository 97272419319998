//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import YocketProductsRedirectionsDropdown from '../YocketProductsRedirectionsDropdown.vue';
import Chevron from '~/assets/Icons/Chevron.vue';
import ProfilePic from '~/components/Profile/ProfilePic.vue';
import { ACQUISITION_SOURCE_CTA } from '~/constants/authFlows';
import { ROUTES } from '~/constants/pageRoutes';
import analyticEvents, { ANALYTIC_SOURCE_MODULES } from '~/services/analyticEvents';

// importing icons
import { chevronGrayBold } from '~/static/icons/index';

export default {
    name: 'NavbarPwa',
    components: {
        ProfilePic,
        Chevron,
        YocketProductsRedirectionsDropdown
    },
    data() {
        return {
            // ICONS
            chevronGrayBold,

            // CONSTANTS
            ROUTES,
            analyticEvents,
            ANALYTIC_SOURCE_MODULES,

            // variables
            showYocketProductsDropdown: false
        };
    },
    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            isAuthenticated: 'auth/isAuthenticated',
            isPremiumUser: 'auth/isPremiumUser'
        })
    },

    methods: {
        handleLogin() {
            this.$store.commit('auth/setSignupCtaSource', ACQUISITION_SOURCE_CTA.nav_signup);
            this.$emit('redirectToLogin', ANALYTIC_SOURCE_MODULES.MOBILE_NAVBAR);
        },
        toggleYocketProductsDropdown() {
            this.showYocketProductsDropdown = !this.showYocketProductsDropdown;
        },
        goToProfile() {
            if (this.$device.isMobile) {
                this.$router.push(ROUTES.PROFILE);
            }
        },
        openSideBar() {
            this.$store.commit('UIElementsState/setSideBarStatus', true);
        }
    }
};
