import { render, staticRenderFns } from "./NavbarPwa.vue?vue&type=template&id=1bf5b4e8&scoped=true&"
import script from "./NavbarPwa.vue?vue&type=script&lang=js&"
export * from "./NavbarPwa.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bf5b4e8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Chevron: require('/app/assets/Icons/Chevron.vue').default,ProfilePic: require('/app/components/Profile/ProfilePic.vue').default})
