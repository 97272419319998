//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import analyticEvents from '~/services/analyticEvents';
import { ROUTES } from '~/constants/pageRoutes';
import { isFeatureAllowed } from '~/services/utilityFunctions';
import { PLATFORM_FEATURES } from '~/static/constants';
const NavbarWeb = () => import('~/components/Navbar/Web/NavbarWeb.vue');
const NavbarTablet = () => import('~/components/Navbar/Tablet/NavbarTablet.vue');
const NavbarPwa = () => import('~/components/Navbar/Pwa/NavbarPwa.vue');
const InstructionsModal = () => import('~/components/Home/InstructionsModal.vue');

export default {
    name: 'Navbar',
    components: {
        NavbarWeb,
        NavbarTablet,
        NavbarPwa,
        InstructionsModal
    },
    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            isAuthenticated: 'auth/isAuthenticated',
            isDashboardSetup: 'auth/isDashboardSetup',
            goToSelectDate: 'priorityPlanner/goToSelectDate',
            isPlannerSetup: 'auth/isPlannerSetup',
            isPremiumUser: 'auth/isPremiumUser',
            userCountry: 'auth/userCountry',
            showYocketBanner: 'auth/showYocketBanner',
            freeTrialStatus: 'auth/freeTrialStatus',
            getUserPremiumFeatures: 'auth/getUserPremiumFeatures',
            isLiveClassUpgradeAvailable: 'auth/isLiveClassUpgradeAvailable',
            isClassesAccessible: 'auth/isClassesAccessible',
            liveTestTaken: 'auth/liveTestTaken',
            showInstructions: 'testSets/showInstructions',
            isLearningAidAccessible: 'auth/isLearningAidAccessible',
            hasPrepFreebies: 'auth/hasPrepFreebies',
            featurePermissions: 'auth/featurePermissions'
        })
    },
    mounted() {
        if (
            this.freeTrialStatus.activated &&
            !this.freeTrialStatus.expired &&
            isFeatureAllowed(PLATFORM_FEATURES.FREE_TRIAL, this.featurePermissions)
        ) {
            /**
             * more than 1 day => 10 mins
             * 1 hour to 1 day => 2 min
             * less than 1 hour => 10sec
             */
            const intervalTime =
                this.freeTrialStatus.remainingTime.days > 0
                    ? 10 * 60 * 1000
                    : this.freeTrialStatus.remainingTime.hours > 1
                    ? 2 * 60 * 1000
                    : 10 * 1000;

            this.updateExpiryInterval = setInterval(() => {
                if (this.freeTrialStatus.expired) {
                    clearInterval(this.updateExpiryInterval);
                    return;
                }
                this.$store.commit('auth/setFreeTrialData');
            }, intervalTime);
        }
    },

    destroyed() {
        clearInterval(this.updateExpiryInterval);
    },

    methods: {
        redirectToLogin(source) {
            if (this.$route.name === 'free-starter-kit') {
                this.$router.push({
                    path: ROUTES.LOGIN,
                    query: { tab: 'signup', src: 'free-starter-kit' }
                });
            } else {
                this.$router.push(ROUTES.LOGIN);
            }
            this.handleAnalyticEvent(analyticEvents.login_click, source);
        },
        handleAnalyticEvent(event, source) {
            this.$store.dispatch('analytics/trackEvent', {
                event,
                data: {
                    source
                }
            });
        }
    }
};
